import {TranslatableStringInterface} from "./translatable-string.interface";
import {AvailabilityInterface} from "./availability.interface";
import {OptionInterface} from "./option.interface";
import {TagInterface} from "./tag.interface";


export enum ProductType {
    UNKNOWN = "",
    MERCHANDISE = "merchandise",
    SERIAL_CARD = "serial_card",
    DELIVERY_COST = "delivery_cost",
    PAYMENT_COST = "payment_cost",
    MEMBERSHIP = "membership",
    SINGLE_EVENT_ACCESS = "single_event_access",
    VALUE_VOUCHER = "value_voucher",
    EXTRA_SERVICE = "extra_service",
}

export const TicketProductTypes = [
    ProductType.SINGLE_EVENT_ACCESS,
    ProductType.MEMBERSHIP,
    ProductType.VALUE_VOUCHER,
    ProductType.SERIAL_CARD,
    ProductType.EXTRA_SERVICE,
];

export const MerchandiseProductTypes = [ProductType.MERCHANDISE];

export type IProductRequirement = {
    text_for_others?: TranslatableStringInterface;
    type: 'campaign_code' | 'membership';
} | {
    type: 'membership_card_type'
    membership_card_types: { id: string }[];
    visibility_for_others: 'hide' | 'text';
    text_for_others?: TranslatableStringInterface;
} | {
    type: 'badge'
    badge_types: { id: string }[];
} | {
    type: 'serial_card'
    serial_cards: { id: string }[];
    visibility_for_others: 'hide' | 'text';
    text_for_others?: TranslatableStringInterface;
} | {
    type: 'other_product'
    other_products: { id: string }[];
    visibility_for_others: 'hide' | 'text' | 'visible';
    text_for_others?: TranslatableStringInterface;
    mode: "all" | "any"
    minimum_count: number;
}

export interface ProductInterface {
    external_buy_button?: {
        url: string;
        label: TranslatableStringInterface;
    };
    id: string;
    title: TranslatableStringInterface;
    price: number;
    type: ProductType;
    description?: TranslatableStringInterface;
    long_description?: TranslatableStringInterface;
    short_info?: TranslatableStringInterface;
    images?: { id: string; url: string }[];
    availability: AvailabilityInterface;
    event_id?: string;
    options?: OptionInterface[];
    forbidden_delivery_options?: { id: string }[];
    forbidden_payment_options?: string[];
    sort_order?: number;
    tags?: TagInterface[];
    slug?: string;
    auto_slug?: string;
    own_product_id?: string;
    folder_ids?: string[];
    requirements?: IProductRequirement[];
    allowed_seatsio_categories?: { category: { key: string }, price?: number }[];
    suggested_products?: { product: ProductInterface }[];
    flags?: string[]
}
